import BaseNativeApi from '../v1.0/native-api';
import MobileEvent from './mobile-event';

export default class NativeApiV1_1 extends BaseNativeApi {
  protected minimum7AppVersion = {
    android: '11.41.0',
    iOS: '11.41.0',
  };

  protected mobileEventTriggers: {[key: string]: Function} = {};

  registerMobileEventTrigger(eventName: string, callback: Function) {
    this.mobileEventTriggers[eventName] = callback;
  }

  async mobileEventTrigger(mobileEventData: string) {
    const mobileEvent = JSON.parse(mobileEventData) as MobileEvent;
    if (mobileEvent.event in this.mobileEventTriggers) {
      await this.mobileEventTriggers[mobileEvent.event](mobileEvent);
    }
  }
}
