import NativeApiV1 from './v1.0/native-api';
import NativeApiV1_1 from './v1.1/native-api';

export function getVersion(version: string): NativeApiV1 | NativeApiV1_1 {
  switch (version) {
    case '1.0':
      return new NativeApiV1();
    case '1.1':
      return new NativeApiV1_1();
    default:
      return new NativeApiV1_1();
  }
}
