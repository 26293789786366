import {v4} from 'uuid';
import {InitConfig} from './lib/types';
import constant from './config';
import {getVersion} from './lib/version';
import NativeApiV1 from './lib/v1.0/native-api';
import NativeApiV1_1 from './lib/v1.1/native-api';

export class SevenAppSDK {
  private version: string;
  private clientId: string;
  public static nativeApi: NativeApiV1 | NativeApiV1_1;

  constructor(initConfig: InitConfig) {
    this.version = initConfig.version;
    this.clientId = initConfig.clientId;
    SevenAppSDK.nativeApi = getVersion(this.version);
  }

  async init() {
    return await SevenAppSDK.nativeApi.callToMobile(
      {
        ...SevenAppSDK.nativeApi.getRequestWithFunctionTemplate(),
        requestTrans: v4(),
        clientID: this.clientId,
        minimumVersion: SevenAppSDK.nativeApi.getMinimum7AppVersion(),
        functionName: 'checkCompatibility',
      },
      constant.timeout.init
    );
  }

  async registerAllMember(options: object) {
    return await SevenAppSDK.nativeApi.callToMobile(
      {
        ...SevenAppSDK.nativeApi.getRequestWithFunctionTemplate(),
        requestTrans: v4(),
        functionName: 'registerAllMember',
        clientID: this.clientId,
        data: {
          ...SevenAppSDK.nativeApi.getRequestWithFunctionTemplate().data,
          options: JSON.stringify(options) || '{}',
        },
      },
      constant.timeout.registerAllMember
    );
  }

  async registerAllMemberAndRedirect(
    callbackUrlSuccess: string,
    callbackUrlFail: string,
    options?: any
  ) {
    return await SevenAppSDK.nativeApi.callToMobile(
      {
        ...SevenAppSDK.nativeApi.getRequestWithRedirectTemplate(),
        requestTrans: v4(),
        functionName: 'registerAllMember',
        clientID: this.clientId,
        data: {
          ...SevenAppSDK.nativeApi.getRequestWithRedirectTemplate().data,
          callbackSuccess:
            callbackUrlSuccess || constant.register.defaultUrlSuccess,
          callbackFail: callbackUrlFail || constant.register.defaultUrlFail,
          options: JSON.stringify(options) || '{}',
        },
      },
      constant.timeout.registerAllMemberAndRedirect
    );
  }

  async registerCustomer(options?: any) {
    return await SevenAppSDK.nativeApi.callToMobile(
      {
        ...SevenAppSDK.nativeApi.getRequestWithFunctionTemplate(),
        requestTrans: v4(),
        functionName: 'registerCustomer',
        clientID: this.clientId,
        data: {
          ...SevenAppSDK.nativeApi.getRequestWithFunctionTemplate().data,
          options: JSON.stringify(options) || '{}',
        },
      },
      constant.timeout.registerCustomer
    );
  }

  async registerCustomerAndRedirect(
    callbackUrlSuccess: string,
    callbackUrlFail: string,
    options?: any
  ) {
    return await SevenAppSDK.nativeApi.callToMobile(
      {
        ...SevenAppSDK.nativeApi.getRequestWithRedirectTemplate(),
        requestTrans: v4(),
        functionName: 'registerCustomer',
        clientID: this.clientId,
        data: {
          ...SevenAppSDK.nativeApi.getRequestWithRedirectTemplate().data,
          callbackSuccess:
            callbackUrlSuccess || constant.register.defaultUrlSuccess,
          callbackFail: callbackUrlFail || constant.register.defaultUrlFail,
          options: JSON.stringify(options) || '{}',
        },
      },
      constant.timeout.registerCustomerAndRedirect
    );
  }

  closeWebview(): void {
    SevenAppSDK.nativeApi.callToMobileSync({
      ...SevenAppSDK.nativeApi.getRequestWithFunctionTemplate(),
      requestTrans: v4(),
      functionName: 'closeWebView',
      clientID: this.clientId,
    });
  }

  openMyCoupon(): void {
    SevenAppSDK.nativeApi.callToMobileSync({
      ...SevenAppSDK.nativeApi.getRequestWithFunctionTemplate(),
      requestTrans: v4(),
      functionName: 'openMyCoupon',
      clientID: this.clientId,
    });
  }

  async getSealedToken() {
    return await SevenAppSDK.nativeApi.callToMobile(
      {
        ...SevenAppSDK.nativeApi.getRequestWithFunctionTemplate(),
        requestTrans: v4(),
        functionName: 'getSealedToken',
        clientID: this.clientId,
      },
      constant.timeout.getSealedToken
    );
  }

  async getUserStatus() {
    return await SevenAppSDK.nativeApi.callToMobile(
      {
        ...SevenAppSDK.nativeApi.getRequestWithFunctionTemplate(),
        requestTrans: v4(),
        functionName: 'getUserStatus',
        clientID: this.clientId,
      },
      constant.timeout.getUserStatus
    );
  }

  bindNativeMuteUnmuteEvent(fn: Function) {
    if (this.version === '1.1') {
      this.bindNativeEvent('muteSound', fn);
      this.bindNativeEvent('unmuteSound', fn);
    }
  }

  bindNativeEvent(eventName: string, fn: Function) {
    if (this.version === '1.1') {
      (SevenAppSDK.nativeApi as NativeApiV1_1).registerMobileEventTrigger(
        eventName,
        fn
      );
    }
  }
}
